import { combineReducers } from "redux"
import propertyReducer from "./propertyReducer"
import coreReducer from "./coreReducer"
import searchReducer from "./searchReducer"

const rootReducer = combineReducers({
  properties: propertyReducer,
  core: coreReducer,
  search: searchReducer,
})

export default rootReducer
