import React, { useState, useEffect, useCallback } from "react"
import Carousel, { Modal, ModalGateway } from "react-images"
import { getTenantDomain } from "../../../helper"
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "../../../assets/photo.css";

import BrockImg from "../../../assets/images/Broke-img.jpg"

export default function PropertyImage({ isBlockView, images, property, tenant, width = "177px", height = "169px", thumbnails = false}) {

  const imagePath = `${getTenantDomain(tenant)}/tenancy/assets/`

  const [mainImage, setMainImage] = useState(
    images.length > 0
      ? `${imagePath}properties/gallery/thumbnails/${images[0].filename}`
      : property.featured_image
      ? `${imagePath}${property.featured_image}`
      : "https://via.placeholder.com/" + (width === 'auto' ? "150" : width)
  )
  const [hoverImageIndex, setHoverImageIndex] = useState(0)
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  // Change the displayed image on hover
  useEffect(() => {
    if (images.length > 1) {
      const interval = setInterval(() => {
        setHoverImageIndex((hoverImageIndex + 1) % images.length)
        setMainImage(
          `${imagePath}properties/gallery/thumbnails/${images[hoverImageIndex].filename}`
        )
      }, 1000) // change image every second
      return () => clearInterval(interval)
    }
  }, [hoverImageIndex, images])

  const openLightbox = useCallback(() => {
    if (images.length === 0) return
    setCurrentImage(hoverImageIndex)
    setViewerIsOpen(true)
  }, [hoverImageIndex])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  let imageSet;
  if (images.length === 0) {
    imageSet = [
      {
        original: BrockImg,
        loading: 'lazy',
        originalWidth: 'auto',
        originalHeight: '300px',
      },
    ];
  } else {
    imageSet = images.map((img) => ({
      fullscreen: img.filename.length === 0 ? BrockImg : `${imagePath}/${img.original_file_path}`,
      original: img.filename.length === 0 ? BrockImg : `${imagePath}properties/gallery/thumbnails/${img.filename}`,
      thumbnail: img.filename.length === 0 ? BrockImg : `${imagePath}properties/gallery/thumbnails/${img.filename}`,
      loading: 'lazy',
      thumbnailLoading: 'lazy',
    }));
  }












  // if(thumbnails) {
  //   var imageSet = images.map((img) => ({
  //     original: `${imagePath}properties/gallery/${img.filename}`,
  //     thumbnail: `${imagePath}properties/gallery/thumbnails/${img.filename}`,
  //     loading: 'lazy',
  //     thumbnailLoading: 'lazy',
  //     orignalWidth: 'auto',
  //     originalHeight: '300px',
  //     thumbnailHeight: '60px',
      
  //   }))
  // } else {
  //   var imageSet = images.map((img) => ({
  //     original: `${imagePath}properties/gallery/${img.filename}`,
  //     thumbnail: `${imagePath}properties/gallery/thumbnails/${img.filename}`,
  //     loading: 'lazy',
  //     thumbnailLoading: 'lazy',
  //     orignalWidth: 'auto',
  //     originalHeight: '300px',
  //     thumbnailHeight: '60px',
  //   }))
    
  // }

  

  const handleScreenChange = (fullScreenElement) => {
    const imgElements = document.querySelectorAll('.image-gallery-content .image-gallery-slide .image-gallery-image');
    const thumbElements = document.querySelectorAll('.image-gallery-thumbnails-wrapper .image-gallery-thumbnail .image-gallery-thumbnail-inner img');
    // console.log(imgElements)
    // console.log(thumbElements)
    // Get the height of the viewport
    const viewportHeight = window.innerHeight;

    // Subtract 80 pixels from the viewport height
    const computedHeight = viewportHeight - 180;

    if (fullScreenElement) {
        console.log('Entered fullscreen mode');
        if (imgElements) {
          imgElements.forEach(imgElement => {
            imgElement.style.height = computedHeight + 'px';
          });
        }
        if (thumbElements) {
          thumbElements.forEach(thumbElement => {
            thumbElement.style.height = 'auto';
          });
        }
    } else {
        console.log('Exited fullscreen mode');
        if (imgElements) {
          imgElements.forEach(imgElement => {
            imgElement.style.height = 'auto';
          });
        }
        if (thumbElements) {
          thumbElements.forEach(thumbElement => {
            thumbElement.style.height = '60px';
          });
        }
    }
  }

  

  return (
    <div>
      <ImageGallery lazyLoad={true} showNav={isBlockView} showThumbnails={isBlockView} showFullscreenButton={isBlockView} items={imageSet} onScreenChange={handleScreenChange} thumbnail={thumbnails} showPlayButton={false} />
      {/* <div
        style={{
          height: height,
          width: width,
          backgroundImage: `url(${mainImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onClick={openLightbox}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel currentIndex={currentImage} views={imageSet} />
          </Modal>
        ) : null}
      </ModalGateway> */}
    </div>
  )
}
