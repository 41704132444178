import React, { useState, useEffect, useRef } from 'react';
import { getTenantDomain } from "../../helper"
import { useParams } from 'react-router-dom';

const VoucherInput = ({voucherCode, setVoucherCode, voucherData, setVoucherData}) => {
    const [message, setMessage] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null); // Create a ref for the input
    const params = useParams();
    const abortControllerRef = useRef(null); // Ref for storing the current AbortController

    const validateVoucher = async () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort(); // Abort any ongoing request
        }
        abortControllerRef.current = new AbortController(); // Create a new AbortController


        setIsLoading(true);
        try {
            const response = await fetch(`${getTenantDomain(params.tenant)}/api/iframe/verify-voucher/${voucherCode}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ voucher_code: voucherCode, propertyId: params.id }),
                signal: abortControllerRef.current.signal // Pass the abort signal to the request
            });
            const data = await response.json();
            setMessage(data.message);
            setIsValid(data.valid);
            if(data.valid) {
                setVoucherData(data.coupon);
            } else{
                setVoucherData(null);
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                setMessage('Error validating voucher.');
                setIsValid(false);
            }
        } finally {
            setIsLoading(false);
            inputRef.current && inputRef.current.focus(); // Set focus back to the input
        }
        
    };

   // Debounce function
   const debounce = (func, delay) => {
    let timer;
    return (...args) => {
        if (timer) {
            clearTimeout(timer);
            if (abortControllerRef.current) {
                abortControllerRef.current.abort(); // Abort the previous request
            }
        }
        timer = setTimeout(() => { func.apply(this, args); }, delay);
    };
};

const debouncedValidation = debounce(validateVoucher, 500);

useEffect(() => {
    if (voucherCode.length > 0) {
        debouncedValidation();
    } else {
        setMessage('');
        setIsValid(true);
    }
}, [voucherCode]);

    const handleVoucherChange = (e) => {
        setVoucherCode(e.target.value);
    };

    const inputClass = `formControl ${!isValid ? 'invalid-input' : ''}`;
    const inputClassMessage = `message ${!isValid ? 'invalid-input' : ''}`;

    return (
        <form className="bookingForm">
            <div className="colsHolder">
                <div className="chCol chCol12">
                    <div className="formGroup">
                        <input
                            type="text"
                            className={inputClass}
                            placeholder="Enter voucher code"
                            value={voucherCode}
                            onChange={handleVoucherChange}
                            ref={inputRef}
                            disabled={isLoading}
                        />
                        {message && <div className={inputClassMessage}>{message}</div>}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default VoucherInput;
