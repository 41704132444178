import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const SuccessPage = () => {
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <div className="text-center bg-light p-5 rounded">
        <i
          className="fas fa-check-circle fa-5x text-success mb-3"
          style={{
            marginBottom: 15,
          }}
        ></i>
        <h2 className="display-5 text-dark">{t("book_success")}</h2>
        <p className="lead my-4 text-secondary">{t("apt_confirm")}</p>
        <Link to="/" className="btn btn-success btn-lg">
          {t("back_home")}
        </Link>
      </div>
    </div>
  )
}

export default SuccessPage
