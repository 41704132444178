import { getTenantDomain } from '../helper';
import { FETCH_REQUISITES_SUCCESS } from './types';

export const coreActionsSuccess = (data) => ({
  type: FETCH_REQUISITES_SUCCESS,
  payload: data,
});


export const fetchRequisites = (tenant) => {
  return (dispatch) => {
    // Make API call to fetch properties
    fetch(`${getTenantDomain(tenant)}/api/iframe/pre-requisites`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(coreActionsSuccess(data));
      })
      .catch((error) => {
        console.log('Error fetching requisites:', error);
      });
  };
};





