import React, { useState, useEffect, forwardRef, useRef } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import dropdownIcon from "../../../assets/images/dropdown.svg"
import { useTranslation } from "react-i18next"

const CalendarDropdown = forwardRef(
  ({ value, onChange, isActive, placeholder, id, forView, isError, disabledDates, filterDate, minDate }) => {
    const [startDate, setStartDate] = useState(value ? new Date(value) : null)
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation()

    const options = { month: "short", year: "2-digit" }
    const formattedDate = startDate
      ? new Date(startDate).toLocaleDateString("en-US", options)
      : ""

    const day = startDate ? new Date(startDate).getDate() : ""
    const formattedDay = day < 10 ? `0${day}` : day

    const finalFormattedDate = startDate
      ? `${formattedDay} ${formattedDate}`
      : ""
    const ref = useRef(null)
    useEffect(() => {
      if (isActive) {
        document.body.classList.add("datepicker-open")
      } else {
        document.body.classList.remove("datepicker-open")
      }
      return () => {
        document.body.classList.remove("datepicker-open")
      }
    }, [isActive])

    const handleDateChange = (date) => {
      setStartDate(date)
      onChange(date)
      setIsOpen(false)
    }
    const scrollToLastFruit = () => {
      const lastChildElement = ref.current?.lastElementChild
      lastChildElement?.scrollIntoView({ behavior: "smooth" })
    }
    const handleInputClick = () => {
      setIsOpen(true)

      setTimeout(() => {
        scrollToLastFruit()
      }, 0)
    }
    return (
      <>
        {forView !== "mobile" ? (
          <div
            className={`formGroup calenderWrap ${isError ? "error" : ""}  ${
              isActive ? "datepicker-active" : ""
            }`}
          >
            <input
              onClick={() => setIsOpen(true)}
              value={finalFormattedDate}
              readOnly
              onChange={() => {}}
              className="formControl hasDatepicker"
              type="text"
              id={id}
              placeholder={placeholder}
            />
            <img src={dropdownIcon} className="dropdownArrow" alt="dropdown" />
            {isOpen && (
              <div className="rental-dp-calender">
                <DatePicker
                  filterDate={filterDate}
                  selected={startDate}
                  onChange={handleDateChange}
                  minDate={minDate}
                  inline
                />
              </div>
            )}
            {isError && (
              <small className="error-text">{t("date_input_error_text")}</small>
            )}
          </div>
        ) : (
          <div
            className={`formGroup calenderWrap ${isError ? "error" : ""}  ${
              isActive ? "datepicker-active" : ""
            }`}
          >
            <input
              onClick={handleInputClick}
              value={finalFormattedDate}
              readOnly
              onChange={() => {}}
              className="formControl hasDatepicker"
              type="text"
              id={id}
              placeholder={placeholder}
            />
            <img src={dropdownIcon} className="dropdownArrow" alt="dropdown" />
            {isOpen && (
              <div ref={ref} className="rental-dp-calender">
                <DatePicker
                  filterDate={filterDate}
                  selected={startDate}
                  onChange={handleDateChange}
                  minDate={minDate}
                  inline
                />
              </div>
            )}
            {isError && (
              <small className="error-text">{t("date_input_error_text")}</small>
            )}
          </div>
          // You can duplicate the above code here for the mobile view as well.
        )}
      </>
    )
  }
)

export default CalendarDropdown
