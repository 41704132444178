import { FETCH_PROPERTIES_SUCCESS, FETCH_PROPERTY_SUCCESS } from '../_actions/types';


const initialState = {
  properties: [],
  property: null,
};
  
const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROPERTIES_SUCCESS:
      return {
        ...state,
        properties: action.payload,
      };
    case FETCH_PROPERTY_SUCCESS:
      return {
        ...state,
        property: action.payload,
      };
    default:
      return state;
  }
};

export default propertyReducer;
