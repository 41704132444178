import React, { useEffect, useRef, useState } from "react"
import {
  arrivalDropdownHandler,
  closeAllDropdown,
  departureDropdownHandler,
  setArrivalDate,
  setDepartureDate,
  setIsArrival,
  setIsDeparture,
  setIsMobileOpen,
} from "../../../_actions/searchActions"
import Dropdown from "./Dropdown"
import searchIcon from "../../../assets/images/icnSearch.svg"

import { useDispatch, useSelector } from "react-redux"
import CityDropdown from "./CityDropdown"
import CalendarDropdown from "./CalendarDropdown"
import { fetchProperties } from "../../../_actions/propertyActions"
import { useHistory, useParams } from "react-router-dom"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useTranslation } from "react-i18next"
import moment from "moment"

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const SearchBar = ({ disabledDates }) => {
  const [DisabledDates, setDisabledDates] = useState([])
  const MySwal = withReactContent(Swal)

  useEffect(() => {
    setDisabledDates(disabledDates)
  }, [disabledDates])

  const params = useParams()
  const properties = useSelector((state) => state.properties.properties)
  const dispatch = useDispatch()
  const {
    isArrival,
    isDeparture,
    arrivalDate,
    departureDate,
    errorDate,
    pageNumber,
    selectedCity,
    persons,
    types,
  } = useSelector((state) => state.search)
  const lngs = {
    en: { nativeName: "English" },
    sk: { nativeName: "Slovak" },
  }

  const history = useHistory()
  const submitHandler = async (e) => {
    e.preventDefault()
    dispatch(closeAllDropdown())
    
    const location = selectedCity
    const startDate = arrivalDate
    const endDate = departureDate
    const adults = persons.adults
    const children = persons.children
    const pets = persons.pets
    const type = types
      .filter((tag) => tag.isSelected)
      .map((t) => t.name)
      .join(",")

    const options = {
      location,
      startDate,
      endDate,
      adults,
      children,
      pets,
      type,
    }
    dispatch(fetchProperties(pageNumber, options, params.tenant))
    dispatch(setIsMobileOpen(false))

    // goto the main page
    history.push('/' + params.tenant)
  }
  const departureDateInput = useRef(null)
  const { t, i18n } = useTranslation()


  const isCheckoutDateDisabled = (date) => { 
    if (!DisabledDates) {
      return true;
    }
    if (arrivalDate === '' || arrivalDate === null) {
      return false;
    }
    if (date < (arrivalDate)) {
      return true;
    }

    if (disabledDates.length === 0) {
      return false;
    }
    const firstDisabledDate = disabledDates.find(
      (dateStr) => {
        return dateStr >= arrivalDate
      }
    );

    return firstDisabledDate ? date < (firstDisabledDate) : false;
  }


  return (
    <>
      <form className="languageForm desktop">
        <select
          value={i18n.resolvedLanguage}
          onChange={(event) => i18n.changeLanguage(event.target.value)}
        >
          {Object.keys(lngs).map((lng) => (
            <option key={lng} value={lng}>
              {lng}
            </option>
          ))}
        </select>
      </form>
      <div className="searchBarMobileWrap">
        <form className="tpSearchBar">
          <div className="formBody">
            <div className="topBarWrapper">
              <span
                className="filterCloser"
                onClick={() => dispatch(setIsMobileOpen(false))}
              >
                <i className="fa-solid fa-angle-left fa-2xs"></i>
                {t("back")}
              </span>
              <form className=" desktop">
                <select
                  value={i18n.resolvedLanguage}
                  onChange={(event) => i18n.changeLanguage(event.target.value)}
                >
                  {Object.keys(lngs).map((lng) => (
                    <option key={lng} value={lng}>
                      {lngs[lng].nativeName}
                    </option>
                  ))}
                </select>
              </form>
            </div>

            <div className="innerBodyWrap">
              <CityDropdown forView={"cityInputWrap"} />
              <CalendarDropdown
                disabledDates={DisabledDates}
                forView={"mobile"}
                id={"arrivalMobile"}
                placeholder={t("arrival")}
                isActive={isArrival}
                handleClick={() => {
                  dispatch(arrivalDropdownHandler())
                }}
                value={arrivalDate}
                onChange={(date) => dispatch(setArrivalDate(date))}
                //   isError={errorDate}
              />

              <CalendarDropdown
                disabledDates={DisabledDates}
                filterDate={isCheckoutDateDisabled}
                forView={"mobile"}
                id={"departureMobile"}
                placeholder={t("departure")}
                isActive={isDeparture}
                handleClick={() => dispatch(departureDropdownHandler())}
                onChange={(date) => dispatch(setDepartureDate(date))}
                value={departureDate}
                minDate={(arrivalDate ? moment(arrivalDate).add(1, 'days').toDate() : new Date())}
                isError={errorDate}
              />
              <Dropdown forView={"mobile"} />
              <div>
                <div className="btnWrap">
                  <button
                    type="button"
                    onClick={submitHandler}
                    className="btn btnPrimary btnSubmit"
                  >
                    {t("search")}
                  </button>
                </div>
                <div className="inputBtnWrap resultWrap dNone">
                  <div className="resultTitle">
                    <span className="resultCountNumber">27</span>
                    <span className="resultCountText">{t("results_found")}</span>
                  </div>
                  <button
                    type="button"
                    className="btnPrimary"
                    onClick={submitHandler}
                  >
                    {t("search")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="searchBarDesktpoWrap">
        <form className="tpSearchBar">
          <div className="colsHolder">
            <div className="chCol chCol2">
              <CityDropdown forView={"dropdownWrap"} />
            </div>
            <div className="chCol chCol2">
              <div className="rental-dp-calender-main">
                <DatePicker
                  placeholderText={t("arrival")}
                  className="formControl"
                  excludeDates={DisabledDates}
                  // onCalendarOpen={() => dispatch(setIsArrival(true))}
                  // onCalendarClose={() => dispatch(setIsArrival(false))}
                  // onClickOutside={() => dispatch(setIsArrival(false))}
                  selected={arrivalDate}
                 
                  onChange={(date) => {
                    console.log('disabled dates', DisabledDates)
                    // First, check if the selected date is before the departure date
                    if (departureDate && date >= departureDate) {
                      // If not, alert the user and do not proceed to update the date
                      // reset the departure date
                      dispatch(setDepartureDate(null));
                      dispatch(setArrivalDate(date));
                      departureDateInput.current.setFocus();

                      return;
                    }
                    // Assuming DisabledDates is an array of Date objects
                    
                    if(!DisabledDates || DisabledDates.length === 0)  {
                      var isRangeValid = true;
                    }else {
                      var isRangeValid = DisabledDates && DisabledDates.length > 0 && !DisabledDates.some(disabledDate => 
                        disabledDate > date && 
                        departureDate && 
                        disabledDate < departureDate
                      );
                    }
                      

                    if (!isRangeValid) {
                      MySwal.fire(<p style={{lineHeight: '2'}}>Selected range includes booked dates. Please select a different range</p>, null, 'error')
                      // Handle the invalid range - possibly by resetting the departure date or alerting the user
                      // Example: Reset departure date
                      dispatch(setDepartureDate(null));
                    } else {
                      // If the range is valid, proceed to set the arrival date
                      dispatch(setArrivalDate(date));
                      // And move focus to the departure date picker
                      departureDateInput.current.setFocus();
                    }
                  }}
                  selectsStart
                  startDate={arrivalDate}
                  endDate={departureDate}
                  monthsShown={2}
                  dateFormat="dd/MM/yyyy"
                  // multiple={false}
                  // defaultChecked={false}
                  // numberOfMonths={2}
                  // disableMonthPicker
                  // disableYearPicker
                  minDate={new Date()}
                  // value={arrivalDate}
                />
              </div>
            </div>
            <div className="chCol chCol2">
              <div className="rental-dp-calender-main">
                <DatePicker
                  placeholderText={t("departure")}
                  filterDate={isCheckoutDateDisabled}
                  className="formControl"
                  // onCalendarOpen={() => dispatch(setIsDeparture(true))}
                  // onCalendarClose={() => dispatch(setIsDeparture(false))}
                  // onClickOutside={() => dispatch(setIsDeparture(false))}
                  monthsShown={2}
                  dateFormat="dd/MM/yyyy"
                  selected={departureDate}
                  onChange={(date) => {
                    if(!DisabledDates || DisabledDates.length === 0)  {
                      var isRangeValid = true;
                    }else {
                      var isRangeValid = !DisabledDates.some(disabledDate => 
                        disabledDate >= arrivalDate && 
                        disabledDate < date
                      );
                    }
                      
                    if (!isRangeValid) {
                      MySwal.fire(<p style={{lineHeight: '2'}}>Selected range includes booked dates. Please select a different range</p>, null, 'error')
                    } else {
                      // If the range is valid, proceed to set the departure date
                      dispatch(setDepartureDate(date));
                    }
                  }}
                  selectsEnd
                  startDate={arrivalDate}
                  endDate={departureDate}
                  // minDate={arrivalDate}
                  minDate={(arrivalDate ? moment(arrivalDate).add(1, 'days').toDate() : new Date())}
                  ref={departureDateInput}
                  // numberOfMonths={2}
                  // disableMonthPicker
                  // disableYearPicker
                  // minDate={arrivalDate}
                  // value={departureDate}
                />
              </div>
            </div>

            <div className="chCol chCol2">
              <Dropdown forView={"deskTop"} />
            </div>
            <div className="chCol chCol2">
              <div className="formGroup resultWrap">
                <div className="resultTitle">
                  <span className="resultCountNumber">{properties.total}</span>
                  <span className="resultCountText">{t("results_found")}</span>
                </div>
              </div>
            </div>
            <div className="chCol chCol2 ">
              <div className="submitBtn" onClick={submitHandler}>
                <a href="#" className="btnPrimary">
                  {t("search")}
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="searchInputWrap">
        <button
          onClick={() => dispatch(setIsMobileOpen(true))}
          type="text"
          className="fromControl filterOpener active filter-open"
          title={t("find_acc")}
        >
          {t("find_acc")}
          <span className="icn">
            <img src={searchIcon} alt="search icn" />
          </span>
        </button>
      </div>
    </>
  )
}

export default SearchBar
