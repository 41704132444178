import React, { FC, useEffect } from "react"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"

interface Props {
  setStripe: (stripe: any) => void
  setElements: (elements: any) => void
  setError: (error: string | null) => void
  error: string | null
  formRef: React.RefObject<HTMLFormElement> // Declare a new prop for formRef
  handleSubmit: (stripe: any, elements: any) => Promise<void> // Define a prop for the handleSubmit function
  isChecked: boolean // Add a prop for isChecked
  setChecked: (isChecked: boolean) => void // Add a prop for setChecked
}

const CheckoutForm: FC<Props> = ({
  setStripe,
  setElements,
  setError,
  error,
  formRef,
  handleSubmit,
  isChecked,
  setChecked,
}) => {
  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    setStripe(stripe)
    setElements(elements)
  }, [stripe, elements, setStripe, setElements])

  const handleChange = (e: any) => {
    if (e.error) {
      setError(e.error.message)
    } else {
      setError(null)
    }
  }

  return (
    <form ref={formRef} className="bookingForm">
      <div className="colsHolder">
        <div className="chCol chCol12">
          <div className="formGroup">
            <label>
              Name
              <input
                type="text"
                id="inputCardholderName"
                className="formControl"
                placeholder="Name on card"
              />
            </label>
          </div>
        </div>
        <div className="chCol chCol12">
          <div className="formGroup">
            <label>
              Card Number
              <CardNumberElement
                className="formControl"
                onChange={handleChange}
              />
              {/* {error && <p className="error-message">{error}</p>} */}
            </label>
          </div>
        </div>
        <div className="chCol chCol6">
          <div className="formGroup">
            <label>
              Expiry Date
              <CardExpiryElement
                className="formControl"
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        <div className="chCol chCol6">
          <div className="formGroup">
            <label>
              CVV
              <CardCvcElement className="formControl" onChange={handleChange} />
            </label>
          </div>
        </div>
      </div>
      <div className="termConditionWrapper">
        <span className="formConfirmText">
          Prečítajte si podmienky zrušenia | <a href="#">Storno podmienky</a>
        </span>
        <ul className="listUnstyled txtList">
          <li>
            Hosť môže zrušiť rezerváciu bezplatne do 14 dní pred príchoodom.
          </li>
          <li>
            Hosť zaplatí 100% ccelkovej ceny rezervácie v prípade zrušenia v
            priebehu 14 dní pred príchodom
          </li>
          <li>
            Pokiaľ klient nesúhlasí s ubytovacími podmienkaami (VOP) ná možnosť
            rezerváciu do 60 minút od vytvorenie rezervácie bezplatne stornovať.
          </li>
        </ul>

        <div className="checkBoxWrap">
          <input
            type="checkbox"
            id="terms_and_conditions"
            checked={isChecked}
            
          />
          <label onClick={() => setChecked(!isChecked)} className="formConfirmText">
            Súhlasím s <a href="#">obchodnými podmienkami</a> a{" "}
            <a href="#">zásadamy ochrany osobných údajov</a>
          </label>
        </div>
      </div>
    </form>
  )
}

export default CheckoutForm
