/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

function NotFoundPage() {
  return (
    <>
     <div style={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', 
        flexDirection: 'column'
      }}>
      <div className="text-center bg-light p-5 rounded">
        <h2 className="display-5 text-dark">Cannot Find Tenant</h2>
        <p className="lead my-4 text-secondary">
          Please check the tenant's name and try again.
        </p>
      </div>
    </div>
    </>
  );
}
export default NotFoundPage;
