import moment from "moment"
import { FETCH_PROPERTIES_SUCCESS, FETCH_PROPERTY_SUCCESS } from "./types"
import { getTenantDomain } from "../helper"

export const fetchPropertiesSuccess = (properties) => ({
  type: FETCH_PROPERTIES_SUCCESS,
  payload: properties,
})

export const fetchProperties = (page, options, tenant) => {
  const perPage = 10 // Number of properties per page
  const base_url = `${getTenantDomain(tenant)}/api/iframe/properties`

  let url
  if (options) {
    const { location, startDate, endDate, adults, children, pets, type } =
      options
    const params = new URLSearchParams({
      page,
      perPage,
      location,
      startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : '',
      endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : '',
      adults,
      children,
      pets,
      type,
    })
    url = `${base_url}?${params.toString()}`
  } else {
    url = `${base_url}?page=${page}&perPage=${perPage}`
  }
  return (dispatch) => {
    // Make API call to fetch properties
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        dispatch(fetchPropertiesSuccess(data))
      })
      .catch((error) => {
        console.log("Error fetching properties:", error)
      })
  }
}



export const fetchProperty = (id, options, tenant) => {
  const base_url = `${getTenantDomain(tenant)}/api/iframe/get-property/${id}`

  let url
  if(options){
    const { startDate, endDate,  } =
      options
    const params = new URLSearchParams({
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    })
    url = `${base_url}?${params.toString()}`
  }

  return (dispatch) => {
    // Make API call to fetch properties
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        dispatch(FetchPropertySuccess(data))
      })
      .catch((error) => {
        // go back to main page
        // window.location.href = "/"
        console.log("Error fetching property:", error)
      })
  }
}


export const FetchPropertySuccess = (property) => ({
  type: FETCH_PROPERTY_SUCCESS,
  payload: property,
})