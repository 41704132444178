import {
  ADD_CHILDREN_AGE,
  ARRIVAL_DROPDOWN_HANDLER,
  CITY_DROPDOWN_HANDLER,
  CLOSE_ALL_DROPDOWN,
  DECREASE_ADULTS,
  DECREASE_PETS,
  DEPARTURE_DROPDOWN_HANDLER,
  INCREASE_ADULTS,
  INCREASE_CHILDREN,
  INCREASE_PETS,
  PERSON_DROPDOWN_HANDLER,
  REMOVE_CHILDREN_AGE,
  SET_ACTIVE_PROPERTY_ID,
  SET_ARRIVAL_DATE,
  SET_DEPARTURE_DATE,
  SET_IS_ARRIVAL,
  SET_IS_BLOCK_VIEW,
  SET_IS_CITY_DROPDOWN,
  SET_IS_DEPARTURE,
  SET_IS_ERROR_DATE,
  SET_IS_MOBLE_OPEN,
  SET_IS_PERSON_DROPDOWN,
  SET_PAGE_NUMBER,
  SET_SELECTED_CITY,
  SET_SELECTED_PROPERTY,
  SET_TYPES,
} from "./types"

export const increaseAdults = () => {
  return {
    type: INCREASE_ADULTS,
  }
}
export const decreaseAdults = () => {
  return {
    type: DECREASE_ADULTS,
  }
}
export const increaseChildren = () => {
  return {
    type: INCREASE_CHILDREN,
  }
}

export const increasePets = () => {
  return {
    type: INCREASE_PETS,
  }
}
export const decreasePets = () => {
  return {
    type: DECREASE_PETS,
  }
}

export const addChildrenAge = (index, value) => {
  return {
    type: ADD_CHILDREN_AGE,
    payload: { index, value },
  }
}
export const removeChildrenAge = () => {
  return {
    type: REMOVE_CHILDREN_AGE,
  }
}

export const setIsBlockView = (boolean) => {
  return {
    type: SET_IS_BLOCK_VIEW,
    payload: boolean,
  }
}

export const setIsCityDropdown = (boolean) => {
  return {
    type: SET_IS_CITY_DROPDOWN,
    payload: boolean,
  }
}

export const setIsPersonDropdownActive = (boolean) => {
  return {
    type: SET_IS_PERSON_DROPDOWN,
    payload: boolean,
  }
}

export const setIsMobileOpen = (boolean) => {
  return {
    type: SET_IS_MOBLE_OPEN,
    payload: boolean,
  }
}

export const setIsArrival = (boolean) => {
  return {
    type: SET_IS_ARRIVAL,
    payload: boolean,
  }
}
export const setIsDeparture = (boolean) => {
  return {
    type: SET_IS_DEPARTURE,
    payload: boolean,
  }
}

export const setArrivalDate = (date) => {
  return {
    type: SET_ARRIVAL_DATE,
    payload: date,
  }
}
export const setDepartureDate = (date) => {
  return {
    type: SET_DEPARTURE_DATE,
    payload: date,
  }
}
export const setIsErrorDate = (boolean) => {
  return {
    type: SET_IS_ERROR_DATE,
    payload: boolean,
  }
}

export const setActivePropertyId = (id) => {
  return {
    type: SET_ACTIVE_PROPERTY_ID,
    payload: id,
  }
}

export const setSelectedProperty = (property) => {
  return {
    type: SET_SELECTED_PROPERTY,
    payload: property,
  }
}

export const setSelectedCity = (city) => {
  return {
    type: SET_SELECTED_CITY,
    payload: city,
  }
}
export const setPageNumber = (num) => {
  return {
    type: SET_PAGE_NUMBER,
    payload: num,
  }
}
export const setTypes = (id) => {
  return {
    type: SET_TYPES,
    payload: id,
  }
}
export const arrivalDropdownHandler = () => {
  return {
    type: ARRIVAL_DROPDOWN_HANDLER,
  }
}
export const departureDropdownHandler = () => {
  return {
    type: DEPARTURE_DROPDOWN_HANDLER,
  }
}
export const openCityDropdownHandler = () => {
  return {
    type: CITY_DROPDOWN_HANDLER,
  }
}
export const openPersonDropdownHandler = () => {
  return {
    type: PERSON_DROPDOWN_HANDLER,
  }
}
export const closeAllDropdown = () => {
  return {
    type: CLOSE_ALL_DROPDOWN,
  }
}
