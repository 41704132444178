import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import icnPinIcon from "../../../assets/images/icnPin.svg";
import searchIcon from "../../../assets/images/icnSearch.svg";
import {
  openCityDropdownHandler,
  setIsCityDropdown,
  setSelectedCity,
} from "../../../_actions/searchActions";

const CityDropdown = ({ forView }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const cities = useSelector((state) => state.core.cities);
  const { isCityDropdownActive: dropdownActive, selectedCity } = useSelector((state) => state.search);
  
  const dropdownRef = useRef(null);
  useEffect(() => {
    // Function to check if clicked outside of the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
      
    // Add the event listener
    document.addEventListener("mouseup", handleClickOutside);
      
    return () => {
      // Clean up the listener when the component unmounts
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [dropdownRef]);
  
  useEffect(() => {
    const bodyClass = "dropdown-open";
    if (dropdownActive) {
      document.body.classList.add(bodyClass);
    } else {
      document.body.classList.remove(bodyClass);
    }
    
    return () => {
      document.body.classList.remove(bodyClass);
    };
  }, [dropdownActive]);

  const closeDropdown = () => dispatch(setIsCityDropdown(false));
  const openDropdown = () => dispatch(openCityDropdownHandler());
  const cityChangeHandler = (e) => dispatch(setSelectedCity(e.target.value));
  const citySelectHandler = (city) => {
    dispatch(setSelectedCity(city));
    closeDropdown();
  };

  return (
    <div
      className={`formGroup ${forView} ${
        dropdownActive ? "dropdown-active" : ""
      }`}
    >
      {forView === "cityInputWrap" && (
        <h2 className="cityLable">
          {t("city")}
          <span className="icnClose" onClick={closeDropdown}>
            <i className="fa-solid fa-xmark"></i>
          </span>
        </h2>
      )}
      <div className="inputWrap">
        <input
          value={selectedCity}
          onChange={cityChangeHandler}
          onClick={openDropdown}
          className="formControl cityInput"
          type="search"
          placeholder={t("city")}
        />
        <span className="icn">
          <img src={searchIcon} alt="search icon" />
        </span>
      </div>
      
      <div className="customDropdown cityDropdown">
        <ul className="listUnstyled cityResultList" ref={dropdownRef}>
          {cities?.map((city) => (
            <li
              key={city}
              onClick={(e) => {
                e.stopPropagation();
                citySelectHandler(city);
              }}
            >
              <a href="#">
                <span className="icnPin">
                  <img src={icnPinIcon} alt="pin icon" />
                </span>
                {city}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CityDropdown;
