export const FETCH_PROPERTIES_SUCCESS = "FETCH_PROPERTIES_SUCCESS"
export const FETCH_PROPERTY_SUCCESS = "FETCH_PROPERTY_SUCCESS"
export const FETCH_REQUISITES_SUCCESS = "FETCH_REQUISITES_SUCCESS"
export const FETCH_STRIPE_SUCCESS = "FETCH_STRIPE_SUCCESS"

export const EXP_REQUEST = "EXP_REQUEST"
export const EXP_SUCCESS = "EXP_SUCCESS"
export const EXP_ERROR = "EXP_ERROR"
export const EXP_RESET = "EXP_RESET"

export const INCREASE_ADULTS = "INCREASE_ADULTS"
export const DECREASE_ADULTS = "DECREASE_ADULTS"
export const INCREASE_CHILDREN = "INCREASE_CHILDREN"
export const DECREASE_CHILDREN = "DECREASE_CHILDREN"
export const INCREASE_PETS = "INCREASE_PETS"
export const DECREASE_PETS = "DECREASE_PETS"
export const ADD_CHILDREN_AGE = "ADD_CHILDREN_AGE"
export const REMOVE_CHILDREN_AGE = "REMOVE_CHILDREN_AGE"

export const SET_IS_BLOCK_VIEW = "SET_IS_BLOCK_VIEW"

export const SET_IS_CITY_DROPDOWN = "SET_IS_CITY_DROPDOWN"

export const SET_IS_PERSON_DROPDOWN = "SET_IS_PERSON_DROPDOWN"

export const SET_IS_MOBLE_OPEN = "SET_IS_MOBLE_OPEN"

export const SET_IS_ARRIVAL = "SET_IS_ARRIVAL"

export const SET_IS_DEPARTURE = "SET_IS_DEPARTURE"

export const SET_ARRIVAL_DATE = "SET_ARRIVAL_DATE"

export const SET_DEPARTURE_DATE = "SET_DEPARTURE_DATE"

export const SET_IS_ERROR_DATE = "SET_IS_ERROR_DATE"

export const SET_ACTIVE_PROPERTY_ID = "SET_ACTIVE_PROPERTY_ID"

export const SET_SELECTED_PROPERTY = "SET_SELECTED_PROPERTY"

export const SET_CITIES = "SET_CITIES"

export const SET_SELECTED_CITY = "SET_SELECTED_CITY"

export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER"

export const SET_TYPES = "SET_TYPES"

export const ARRIVAL_DROPDOWN_HANDLER = "ARRIVAL_DROPDOWN_HANDLER"
export const DEPARTURE_DROPDOWN_HANDLER = "DEPARTURE_DROPDOWN_HANDLER"
export const CITY_DROPDOWN_HANDLER = "CITY_DROPDOWN_HANDLER"
export const PERSON_DROPDOWN_HANDLER = "PERSON_DROPDOWN_HANDLER"
export const CLOSE_ALL_DROPDOWN = "CLOSE_ALL_DROPDOWN"
