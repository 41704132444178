import React, { useState } from "react"

const FilterTag = ({ text, number, icon, isSelected, onClick }) => {
  return (
    <li
      style={{ cursor: "pointer" }}
      onClick={() => {
        onClick()
      }}
    >
      <div className={`${isSelected ? "appartCountWrap" : "catIndiWrap"}`}>
        <span className="homeIcn">
          <img src={icon} alt="hone Icon" />
        </span>
        <strong>
          {text} ({number})
        </strong>
        {isSelected && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="#"
            className="closeButton"
            onClick={(e) => {
              e.stopPropagation()
              onClick()
            }}
          >
            x
          </a>
        )}
      </div>
    </li>
  )
}

export default FilterTag
